import React, {useEffect, useState} from 'react';
import {Grid} from '@mui/material';
import {Typography} from '@material-ui/core';
import TextField from '@material-ui/core/TextField';
import Box from '@mui/material/Box';
import ProductPromptsList from './ProductPromptsList';
import SelectProductCategoryWidget from './SelectCategoryWidget';
import {SERVER_URL} from '../../../constants';

export default function ProductDetails(props: $TSFixMe) {
  const [prompts, setPrompts] = useState(props.product?.prompts || []);
  const [productCategory, setProductCategory] = useState<any>(
    props.product?.category,
  );

  const updateProduct = () => {
    const jwt = localStorage.getItem('Authorization');
    const body: any = {};

    if (productCategory) {
      body['categoryId'] = productCategory.id;
    } else {
      props.setMode('view');
      return;
    }

    return fetch(`${SERVER_URL}v1/products/${props.product.uid}`, {
      method: 'PATCH',
      headers: {
        'Content-Type': 'application/json',
        Authorization: jwt || '',
      },
      body: JSON.stringify(body),
    })
      .then((res) => {
        props.setMode('view');
      })
      .catch((err) => {
        console.error(err);
        if (err.status === 403) {
          window.location.href = '/login';
        }
      });
  };

  useEffect(() => {
    if (props.mode === 'save') {
      updateProduct();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.mode]);

  if (!props.product) {
    return null;
  }

  return props.mode === 'view' ? (
    <Grid container spacing={2}>
      <Grid item xs={12} sm={12}>
        <Typography>Price: ${props.product.price}</Typography>
      </Grid>
      <Grid item xs={12} sm={12}>
        <Typography>
          Category: {productCategory?.name || 'No category selected'}
        </Typography>
      </Grid>
      <Grid item xs={12} sm={12}>
        <Box mt={2}>
          <Typography>Prompts</Typography>
          <ProductPromptsList
            product={props.product}
            key={'product-prompts-list'}
            prompts={prompts}
            setPrompts={setPrompts}
            mode={'view'}
          />
        </Box>
      </Grid>
    </Grid>
  ) : (
    <Grid container spacing={2}>
      <Grid item xs={12} sm={12}>
        <TextField
          disabled={props.mode === 'save'}
          fullWidth
          label="Product Name"
          variant="outlined"
          value={props.product.name}
          InputLabelProps={{shrink: true}}
          style={{marginBottom: 12}}
        />
        <TextField
          disabled={props.mode === 'save'}
          fullWidth
          label="Price"
          variant="outlined"
          value={props.product.price}
          InputLabelProps={{shrink: true}}
        />
      </Grid>
      <Grid item xs={12} sm={12}>
        <SelectProductCategoryWidget setProductCategory={setProductCategory} />
      </Grid>
      <Grid item xs={12} sm={12}>
        <Box mt={2}>
          <Typography>Prompts</Typography>
          <ProductPromptsList
            product={props.product}
            key={'product-prompts-list'}
            prompts={prompts}
            setPrompts={setPrompts}
            mode={'edit'}
          />
        </Box>
      </Grid>
    </Grid>
  );
}
