import React, {useState, useEffect} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import {currencyFormat} from '../../../util/CurrencyFormatter';
import {SERVER_URL} from '../../../constants';
import Divider from '@material-ui/core/Divider';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';

const useStyles = makeStyles((theme) => ({
  root: {
    border: '1px solid #e6e6e6',
    borderRadius: 8,
    paddingTop: 24,

    '& .MuiTypography-subtitle1': {
      width: 100,
    },
  },
  totalBlock: {
    marginTop: 8,
    backgroundColor: '#e6e6e6',
    padding: '20px 24px',
    borderBottomLeftRadius: 6,
    borderBottomRightRadius: 6,
  },
}));

function Alert(props: $TSFixMe) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const StandardPayoutViewTable = (props: $TSFixMe) => {
  console.log('PAYOUT VIEW TABLE');
  const classes = useStyles();
  const [paymentDetails, setPaymentDetails] = useState<$TSFixMe>(null);
  const [payoutDetails, setPayoutDetails] = useState<$TSFixMe>(null);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');

  const getProposalPaymentTransaction = (proposal: $TSFixMe) => {
    const fbjwt = localStorage.getItem('Authorization');
    return fetch(
      `${SERVER_URL}v1/transactions?type=PAYMENT&proposal_id=${proposal.id}`,
      {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: fbjwt as string,
        },
      },
    )
      .then((res) => res.json())
      .then((res) => {
        setPaymentDetails(res._embedded.models[0]);
      })
      .catch((err) => {
        if (err.status === 403) {
          window.location.href = '/login';
        }
      });
  };

  useEffect(() => {
    getProposalPaymentTransaction(props.proposal);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getProposalPayoutTransaction = () => {
    const fbjwt = localStorage.getItem('Authorization');
    return fetch(
      `${SERVER_URL}v1/transactions?type=PAYOUT&stage_id=${props.stage.id}`,
      {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: fbjwt as string,
        },
      },
    )
      .then((res) => res.json())
      .then((res) => {
        if (res._embedded) {
          setPayoutDetails(res._embedded.models[0]);
        }
      })
      .catch((err) => {
        if (err.status === 403) {
          window.location.href = '/login';
        }
      });
  };

  useEffect(() => {
    getProposalPayoutTransaction();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onClickDownloadReport = () => {
    const url = `${SERVER_URL}v1/transactions/${paymentDetails.id}/report`;
    const fbjwt = localStorage.getItem('Authorization');
    return fetch(url, {
      headers: {
        Authorization: fbjwt as string,
        'Content-Type': 'text/csv',
      },
    })
      .then((res) => res.text())
      .then((res) => {
        const file = new File([res], 'report.csv', {type: 'text/csv'});
        const downloadUrl = window.URL.createObjectURL(file);
        const a = document.createElement('a');
        a.style.display = 'none';
        a.href = downloadUrl;
        a.download = 'report.csv';
        document.body.appendChild(a);
        a.click();
        window.URL.revokeObjectURL(downloadUrl);
        document.body.removeChild(a);
      });
  };

  const approveTransfer = () => {
    const url = `${SERVER_URL}v1/transactions/${payoutDetails.id}/approve-transfer`;
    const fbjwt = localStorage.getItem('Authorization');
    return fetch(url, {
      method: 'PATCH',
      headers: {
        Authorization: fbjwt as string,
        'Content-Type': 'application/json',
      },
    })
      .then((res) => res.json())
      .then((res) => {
        setPayoutDetails(res);

        if (res.state === 'COMPLETE') {
          setSnackbarMessage('Payout Processed Successfully');
          setSnackbarOpen(true);
        }
      });
  };

  return (
    <div className={classes.root}>
      <Box>
        <Grid container justifyContent="space-between">
          <Box ml={3} mb={2}>
            <Typography variant="h6" style={{fontWeight: 'bold'}}>
              PAYMENT
            </Typography>
            <Typography variant="subtitle1">INVOICE</Typography>
            <Typography variant="subtitle1">CONTINGENCY</Typography>
            <Typography variant="subtitle1" style={{marginTop: 20}}>
              SUBTOTAL
            </Typography>
            <Typography variant="subtitle1">TAX</Typography>
            <Typography variant="subtitle1">INVOICE</Typography>
            <Typography variant="subtitle1" style={{marginTop: 20}}>
              FEES
            </Typography>
            <Typography variant="subtitle1">TAX</Typography>
            <Typography variant="subtitle1">TOTAL FEES</Typography>
            <Typography
              variant="subtitle1"
              style={{fontWeight: 'bold', marginTop: 20}}>
              TOTAL
            </Typography>
          </Box>
          <Box mr={3} mb={2}>
            <Typography variant="h6">&nbsp;</Typography>
            <Typography variant="subtitle1">
              {currencyFormat(props.stage.invoiceAmount)}
            </Typography>
            <Typography variant="subtitle1">
              {currencyFormat(props.stage.contingencyAmount)}
            </Typography>
            <Typography
              variant="subtitle1"
              style={{marginTop: 20}}
              data-cy="subtotal">
              {currencyFormat(props.stage.subtotal)}
            </Typography>
            <Typography variant="subtitle1">
              {currencyFormat(props.stage.tax)}
            </Typography>
            <Typography variant="subtitle1">
              {currencyFormat(props.stage.total)}
            </Typography>
            <Typography variant="subtitle1" style={{marginTop: 20}}>
              {currencyFormat(props.stage.fees)}
            </Typography>
            <Typography variant="subtitle1">
              {currencyFormat(props.stage.feesTax)}
            </Typography>
            <Typography variant="subtitle1">
              {currencyFormat(props.stage.feesTotal)}
            </Typography>
            <Typography
              variant="subtitle1"
              style={{fontWeight: 'bold', marginTop: 20}}>
              {currencyFormat(props.stage.grandTotal)}
            </Typography>
          </Box>
        </Grid>
        <Divider />
        <Grid container justifyContent="space-between">
          <Grid item>
            <Box ml={3} mt={2} mb={2}>
              <Typography variant="h6" style={{fontWeight: 'bold'}}>
                PAYOUT
              </Typography>
              <Typography variant="subtitle1">INVOICE</Typography>
              <Typography variant="subtitle1" style={{width: 180}}>
                CONTINGENCY USED
              </Typography>
              <Typography variant="subtitle1" style={{marginTop: 20}}>
                SUBTOTAL
              </Typography>
              <Typography variant="subtitle1">TAX</Typography>
              <Typography variant="subtitle1" style={{fontWeight: 'bold'}}>
                TOTAL
              </Typography>
            </Box>
          </Grid>
          <Grid item>
            <Box mr={3} mt={2} mb={2}>
              <Typography variant="h6">&nbsp;</Typography>
              <Typography variant="subtitle1">
                {currencyFormat(props.stage.invoiceAmount)}
              </Typography>
              <Typography variant="subtitle1">
                {currencyFormat(props.stage.contingencyAmountUsed)}
              </Typography>
              <Typography
                variant="subtitle1"
                style={{marginTop: 20}}
                data-cy="subtotal">
                {currencyFormat(props.stage.payoutSubtotal)}
              </Typography>
              <Typography variant="subtitle1">
                {currencyFormat(props.stage.payoutAmountTax)}
              </Typography>
              <Typography variant="subtitle1" style={{fontWeight: 'bold'}}>
                {currencyFormat(props.stage.payoutAmountTotal)}
              </Typography>
            </Box>
          </Grid>
        </Grid>
        <Grid container>
          <Grid item>
            <Box pl={20}>
              <Typography variant="subtitle1" style={{width: '100%'}}>
                {`Note: Payout Email ${
                  props.proposal.user.billingEmail || props.proposal.user.email
                }`}
              </Typography>
            </Box>
          </Grid>
        </Grid>
        <Divider />
        {props.stage.contingencyRefundAmount > 0 && (
          <>
            <Grid container justifyContent="space-between">
              <Box ml={3} mt={2} mb={2}>
                <Typography variant="h6" style={{fontWeight: 'bold'}}>
                  Contingency Refund
                </Typography>
                <Typography variant="subtitle1" style={{width: 180}}>
                  Account Credit
                </Typography>
              </Box>
              <Box mr={3} mt={2} mb={2}>
                <Typography variant="h6">&nbsp;</Typography>
                <Typography variant="subtitle1">
                  ({currencyFormat(props.stage.contingencyRefundAmount)})
                </Typography>
              </Box>
            </Grid>
            <Divider />
          </>
        )}
        <Grid container justifyContent="space-between">
          <Box ml={3} mt={2} mb={2}>
            <Typography variant="h6" style={{fontWeight: 'bold'}}>
              TRANSFER
            </Typography>
            <Typography variant="subtitle1">Total</Typography>
            {props.stage.contingencyRefundAmount > 0 && (
              <Typography variant="subtitle1" style={{width: 180}}>
                Contingency Refund
              </Typography>
            )}
            <Typography variant="subtitle1">Payout</Typography>
            <Typography variant="subtitle1">FEES</Typography>
            <Typography variant="subtitle1">TAX</Typography>
            <Typography
              variant="subtitle1"
              style={{fontWeight: 'bold', width: 180}}>
              Gross Revenue
            </Typography>
          </Box>
          <Box mr={3} mt={2} mb={2}>
            <Typography variant="h6">&nbsp;</Typography>
            <Typography variant="subtitle1">
              {currencyFormat(props.stage.grandTotal)}
            </Typography>
            {props.stage.contingencyRefundAmount > 0 && (
              <Typography variant="subtitle1">
                ({currencyFormat(props.stage.contingencyRefundAmount)})
              </Typography>
            )}
            <Typography variant="subtitle1">
              {currencyFormat(props.stage.payoutAmountTotal)}
            </Typography>
            <Typography variant="subtitle1">
              {currencyFormat(props.stage.fees)}
            </Typography>
            <Typography variant="subtitle1">
              {currencyFormat(props.stage.feesTax)}
            </Typography>
            <Typography variant="subtitle1" style={{fontWeight: 'bold'}}>
              {currencyFormat(props.stage.stageRevenueAmount)}
            </Typography>
          </Box>
        </Grid>
        <Box mr={3} mt={2} mb={2} ml={2}>
          <Grid container justifyContent="space-between">
            <Grid>
              <Button
                variant={'contained'}
                color={'secondary'}
                onClick={onClickDownloadReport}>
                Download
              </Button>
            </Grid>
            <Grid>
              {payoutDetails && payoutDetails.state === 'PENDING' && (
                <Button
                  variant={'contained'}
                  color={'secondary'}
                  onClick={approveTransfer}>
                  Approve Transfer
                </Button>
              )}
            </Grid>
          </Grid>
        </Box>
      </Box>
      <Snackbar
        open={snackbarOpen}
        anchorOrigin={{vertical: 'top', horizontal: 'center'}}
        autoHideDuration={4000}>
        <Alert>{snackbarMessage}</Alert>
      </Snackbar>
    </div>
  );
};

export default StandardPayoutViewTable;
