import React, {useEffect} from 'react';
import {Autocomplete, DialogTitle} from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import {DialogActions} from '@material-ui/core';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import {createFilterOptions} from '@material-ui/lab/Autocomplete';
import {SERVER_URL} from '../../../constants';

type ProductCategoryOption = {
  id?: number;
  inputValue?: string;
  name: string;
};

const filter = createFilterOptions<ProductCategoryOption>();

export default function SelectProductCategoryWidget(props: $TSFixMe) {
  const [value, setValue] = React.useState<ProductCategoryOption | null>(null);
  const [open, toggleOpen] = React.useState(false);
  const [loading, setLoading] = React.useState(true);

  const [productCategoryOptions, setProductCategoryOptions] = React.useState<
    ProductCategoryOption[]
  >([]);

  const [newProductCategory, setNewProductCategory] = React.useState('');

  useEffect(() => {
    if (value) {
      props.setProductCategory(value);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  const handleClose = () => {
    setNewProductCategory('');
    toggleOpen(false);
  };

  const getProductCategoriesList = () => {
    const jwt = localStorage.getItem('Authorization');
    return fetch(`${SERVER_URL}v1/product-categories`, {
      method: 'GET',
      // @ts-expect-error ts-migrate(2322) FIXME: Type '{ 'Content-Type': string; Authorization: str... Remove this comment to see the full error message
      headers: {
        'Content-Type': 'application/json',
        Authorization: jwt,
      },
    })
      .then((res) => {
        if (res.status === 409) {
          return res;
        } else {
          return res.json();
        }
      })
      .then((res) => {
        console.log(res);
        setProductCategoryOptions(res._embedded?.models || []);
        setLoading(false);
      })
      .catch((err) => {
        console.error(err);
        if (err.status === 403) {
          window.location.href = '/login';
        }
      });
  };

  useEffect(() => {
    getProductCategoriesList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const jwt = localStorage.getItem('Authorization');
    const body = JSON.stringify({
      name: newProductCategory,
    });

    return fetch(`${SERVER_URL}v1/product-categories`, {
      method: 'POST',
      // @ts-ignore
      headers: {
        'Content-Type': 'application/json',
        Authorization: jwt,
      },
      body: body,
    })
      .then((res) => res.json())
      .then((res) => {
        setValue({
          id: res.id,
          name: newProductCategory,
        });
        handleClose();
      });
  };

  if (loading) {
    return null;
  }

  return (
    <>
      <Autocomplete
        style={{width: '100%'}}
        value={value}
        onChange={(event, newValue) => {
          console.log('On change!!!');

          if (typeof newValue === 'string') {
            // timeout to avoid instant validation of the dialog's form.
            setTimeout(() => {
              toggleOpen(true);
              setNewProductCategory(newValue);
            });
          } else if (newValue && newValue.inputValue) {
            toggleOpen(true);
            setNewProductCategory(newValue.inputValue);
          } else {
            setValue(newValue);
          }
        }}
        filterOptions={(options, params) => {
          const filtered = filter(options, params);

          if (params.inputValue !== '') {
            filtered.push({
              inputValue: params.inputValue,
              name: `Add ${params.inputValue}`,
            });
          }

          return filtered;
        }}
        id="select-prompt-autoselect"
        options={productCategoryOptions}
        getOptionLabel={(option) => {
          // for example value selected with enter, right from the input
          if (typeof option === 'string') {
            return option;
          }
          if ('inputValue' in option && option.inputValue) {
            return option.inputValue;
          }
          return option.name;
        }}
        selectOnFocus
        handleHomeEndKeys
        clearOnBlur
        renderOption={(props, option) => {
          // @ts-ignore
          const {key, ...optionProps} = props;
          return (
            <li key={key} {...optionProps}>
              {option.name}
            </li>
          );
        }}
        sx={{width: 300}}
        freeSolo
        renderInput={(params) => (
          <TextField
            {...params}
            label="Select Category"
            color={'secondary'}
            fullWidth
          />
        )}
      />
      <Dialog
        maxWidth={'sm'}
        fullWidth
        open={open}
        onClose={handleClose}
        disableEnforceFocus>
        <form onSubmit={handleSubmit}>
          <DialogTitle>Create Product Category</DialogTitle>
          <DialogContent>
            <TextField
              margin="dense"
              id="new-prompt-name"
              name={'new-prompt-name'}
              value={newProductCategory}
              onChange={(event) => setNewProductCategory(event.target.value)}
              label="Product Category"
              type="text"
              variant="standard"
              color={'secondary'}
              fullWidth
            />
          </DialogContent>
          <DialogActions>
            <Button color={'secondary'} onClick={handleClose}>
              Cancel
            </Button>
            <Button color={'secondary'} type="submit">
              Add
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    </>
  );
}
